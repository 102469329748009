import { LoaderState } from '../core/LoaderState'
import { TimberLayers } from './MapOpenLayersApi/TimberLayers'
const json = require('@/map-layers.json')

interface TimberGetMapOpenLayers {
    (data: Array<TimberLayers>): void;
}

export class TimberMapOpenLayersApi {
    getLayers(onreceive: TimberGetMapOpenLayers, loader: LoaderState) {
        loader(true);
        onreceive(
            json.layers
        );
        loader(false);
    }
}