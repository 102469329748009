<template>
  <nav id="header-main" class="navbar navbar-expand-lg navbar-light fixed-top bg-branco">
    <div class="container-fluid px-md-5">
      <a class="navbar-brand col-9 col-md-auto" href="/">
        <img src="/assets/logo-timberflow-imaflora-brandbook.png" class="logo-header" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0 nav">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="/">Home</a>
          </li>
          <hr class="d-block d-md-none hr-menu">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="/sobre">Sobre</a>
          </li>
          <hr class="d-block d-md-none hr-menu">
          <li class="nav-item">
            <a class="nav-link" href="/fluxo-madeireiro">Fluxo Madeireiro</a>
          </li>
          <hr class="d-block d-md-none hr-menu">
          <li class="nav-item">
            <a class="nav-link" href="/biblioteca">Biblioteca</a>
          </li>
          <hr class="d-block d-md-none hr-menu">
          <li class="nav-item">
            <a class="nav-link" href="/consultaavancada">Consulta Avançada</a>
          </li>
          <hr class="d-block d-md-none hr-menu">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" id="facapartaLink" role="button" data-bs-toggle="dropdown" aria-expanded="false" href="#">Faça Parte</a>
            <ul class="dropdown-menu" aria-labelledby="facapartaLink">
            <li><a class="dropdown-item text-center" href="/facaparte/fale-conosco">Fale Conosco</a></li>
            <li><a class="dropdown-item text-center" href="/facaparte/inscreva-se">Inscreva-se</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Header",
};
</script>