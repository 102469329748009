<template>
    <div class="container-fluid px-0 mx-0 footer-top py-4">
        <div class="col-12 footer-main">
            <div class="container">
                <div class="row justify-content-md-center">
                    <div class="col-md-3 text-center">
                        <p class="footer-tit mt-3 mt-md-auto">Realização</p>
                        <a href="https://www.imaflora.org/" target="_blank"><img src="/assets/logo-timberflow-branco.svg" class="img-fluid mt-3" alt="Imaflora"/></a>
                    </div>
                    <div class="col-md-3 text-center">
                        <p class="footer-tit">Financiador</p>
                        <a href="https://www.porticus.com/en/our-regions/good-energies-foundation" target="_blank"><img src="/assets/good-energies-foundation.png" class="img-fluid" alt="Good Energies Foundation"/></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid px-0 mx-0 footer py-4">
        <div class="col-12 footer-main">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-12 ps-md-0 pe-md-5 mb-5 text-center">
                                <a href="/"> <img src="/assets/logo-branco-timberflow-imaflora-brandbook.png" class="img-fluid" /></a>
                            </div>
                        </div>
                        <div class="row justify-content-between d-none d-md-block">
                            <div class="col-12">
                                <a href="https://web.facebook.com/imaflora" target="_blank"><img src="/assets/logo-fb.svg" class="img-fluid social" /></a>
                                <a href="https://www.instagram.com/imaflorabrasil/" target="_blank"><img src="/assets/logo-instagram.svg" class="img-fluid social" /></a>
                                <a href="https://www.youtube.com/@Imaflora" target="_blank"><img src="/assets/logo-youtube.svg" class="img-fluid social" /></a>
                                <a href="https://twitter.com/Imaflora" target="_blank"><img src="/assets/logo-twitter.svg" class="img-fluid social" /></a>
                                <a href="https://www.linkedin.com/showcase/iniciativalegalidadeflorestal/" target="_blank"><img src="/assets/logo-linkedin.svg" class="img-fluid social" /></a>
                                <a href="https://open.spotify.com/show/1NyaiQVHWrLpcBuUltx50Y" target="_blank"><img src="/assets/logo-spotify.svg" class="img-fluid social" /></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 ">
                        <p class="footer-tit mb-0">Endereço</p>
                        <p class="footer-text">
                            Estrada Chico Mendes, 185<br />
                            Piracicaba - SP | Brasil<br />
                            CEP 13426-420<br />
                        </p>
                        <p class="footer-tit mb-0">Telefone</p>
                        <p class="footer-text">
                            <a href="tel:+551934290800" target="_blank">+55 19 3429.0800</a>
                        </p>
                        <p class="footer-tit mb-0">E-mail</p>
                        <p class="footer-text">
                            <a href="mailto:imaflora@imaflora.org" target="_blank">imaflora@imaflora.org</a>
                        </p>
                    </div>
                    <div class="col-md-3 ">
                        <p class="footer-tit mb-0">Mapa do site</p>
                        <p class="footer-text footer-menu mb-2 mt-3">
                            <a class="nav-link active" aria-current="page" href="/sobre">Sobre</a>
                        </p>
                        <p class="footer-text footer-menu mb-2">
                           <a class="nav-link" href="/fluxo-madeireiro">Fluxo Madeireiro</a>
                        </p>
                        <p class="footer-text footer-menu mb-2">
                            <a class="nav-link" href="/biblioteca">Biblioteca</a>
                        </p>
                        <p class="footer-text footer-menu mb-2">
                            <a class="nav-link" href="/consultaavancada">Consulta Avançada</a>
                        </p>
                        <p class="footer-text footer-menu mb-2">
                            <a class="nav-link mb-0">Faça Parte</a>
                            <a href="/facaparte/fale-conosco" class="ps-3">Fale Conosco</a>
                            <br />
                            <a href="/facaparte/inscreva-se" class="ps-3">Inscreva-se</a>
                        </p>
                    </div>
                    <div class="col-md-3 d-flex flex-column justify-content-between">
                        <p class="footer-tit mb-0">Desenvolvido por<br /><a href="mailto:contato@studiocuboweb.com.br" target="_blank">Studio Cubo</a></p>
                        <p class="footer-text">
                            @Copyright 2022
                        </p>
                    </div>
                    <div class="row justify-content-between d-block d-md-none">
                            <div class="col-12">
                                <a href="https://web.facebook.com/imaflora" target="_blank"><img src="/assets/logo-fb.svg" class="img-fluid social" /></a>
                                <a href="https://www.instagram.com/imaflorabrasil/" target="_blank"><img src="/assets/logo-instagram.svg" class="img-fluid social" /></a>
                                <a href="https://www.youtube.com/@Imaflora" target="_blank"><img src="/assets/logo-youtube.svg" class="img-fluid social" /></a>
                                <a href="https://twitter.com/Imaflora" target="_blank"><img src="/assets/logo-twitter.svg" class="img-fluid social" /></a>
                                <a href="https://www.linkedin.com/showcase/iniciativalegalidadeflorestal/" target="_blank"><img src="/assets/logo-linkedin.svg" class="img-fluid social" /></a>
                                <a href="https://open.spotify.com/show/1NyaiQVHWrLpcBuUltx50Y" target="_blank"><img src="/assets/logo-spotify.svg" class="img-fluid social" /></a>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>