import { BaseTimberApi, IBasicFilterParams, TimberSearchType, TimberStatisticsGetTotal, TimberStatisticsItem } from '@/core/BaseTimberApi';
import { LoaderState } from '../core/LoaderState'

export class TimberEnterprisesApi extends BaseTimberApi {
    constructor(baseUrl: String) {
        super(baseUrl, 'enterprises');
    }
    
    total(type: TimberSearchType, filter: IBasicFilterParams, onreceive: TimberStatisticsGetTotal, loader: LoaderState) {
        loader(true);     

        this.postFilters("total/" + this.parseRouteEndpoint(type), filter, (x => {
            onreceive(x.data as Array<TimberStatisticsItem>);
            loader(false);
        }));
    }
}