<template>
    <div id="page-container">
        <Header />
        <div id="content-wrap">
           <router-view></router-view>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from "../organisms/Layout/Header"
import Footer from "../organisms/Layout/Footer"
export default {
    name:"Layout",
    components:{Header,Footer},
}
</script>

<style lang="scss">
@import "../../assets/sass/site";

html, body {
    height: 100% !important;
    padding: 0;
    margin: 0;
}
/** Allow footer to have absolute position and stick to the bottom */
#page-container {
    padding: 80px 0 0 0; /* header and footer height */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
}
@media screen and (min-width: 768px) {
    #content-wrap {
       min-height: calc(100% - 290px - 48px);
    }
}
@media screen and (max-width: 991px) {
    #page-container {
        overflow-x:hidden;
    }
}
// @media screen and (min-width: 768px) and (max-height: 925px) {
//     #content-wrap {
//         overflow-y: scroll;
//         height: calc(100% - 290px);
//     }
// }
</style>