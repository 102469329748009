export function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export function formatNumber(number,fixed) {
    return Intl.NumberFormat('pt-BR', { minimumFractionDigits: fixed }).format(parseFloat(number).toFixed(fixed));
}

export function formatDate(date) {
    return Intl.DateTimeFormat('pt-BR').format(date);
}

export function formatDateWithMonth(date) {
    return Intl.DateTimeFormat('pt-BR', { month: 'long',year: 'numeric'}).format(date).replace(' de',', ');
}