import { BaseTimberApi, IAdvancedFilterParams } from '@/core/BaseTimberApi';
import { LoaderState } from '../core/LoaderState'

interface TimberAdvancedSearchItem {
    documento: String,
    empresa: String,
    especie: String,
    produto: String,
    registro: String,
    data_emissao: String,
    mun_orig: String,
    mun_dest: String,
    volume: String,
    unidade_medida: String,
}


interface TimberAdvancedSearchGet {
    (data: Array<TimberAdvancedSearchItem>): void;
}

export class TimberAdvancedSearchApi  extends BaseTimberApi {
    constructor(baseUrl: String) {
        super(baseUrl, "advanced-search");
    }

    count(filter: IAdvancedFilterParams, onreceive: TimberAdvancedSearchGet, loader: LoaderState) {
        loader(true);
        this.postAdvancedFilters("transports", filter, (x => {
            onreceive(x.data as TimberAdvancedSearchItem[]);
            loader(false);
        }));
    }   

    get(filter: IAdvancedFilterParams, page: Number, pageSize: Number, onreceive: TimberAdvancedSearchGet, loader: LoaderState) {
        const newFilter: any = filter;
        newFilter.page = page;
        newFilter.pageSize = pageSize;

        loader(true);
        this.post("transports", newFilter, (x => {
            onreceive(x.data as TimberAdvancedSearchItem[]);
            loader(false);
        }));
    }   
}