import { TimberCitiesApi } from './TimberCitiesApi';
import { TimberStatesApi } from './TimberStatesApi';
import { TimberProductsApi } from './TimberProductsApi';
import { TimberSpeciesApi } from './TimberSpeciesApi';
import { TimberMapOpenLayersApi } from './TimberMapOpenLayersApi';
import { TimberEnterprisesApi } from './TimberEnterprisesApi';
import { TimberVolumesApi } from './TimberVolumesApi';
import { TimberAdvancedSearchApi } from './TimberAdvancedSearchApi';
import { TimberLogsApi } from './TimberLogsApi';
import { TimberLibraryApi } from './TimberLibraryApi';
import { TimberDownloadApi } from './TimberDownloadApi';


import { defineComponent } from 'vue';


export default  defineComponent({
    computed: {
        $timberApi() {
            const baseUrl = process.env.VUE_APP_TMAPI_URL;
            return {
                Cities: new TimberCitiesApi(baseUrl),
                States: new TimberStatesApi(baseUrl),
                Products: new TimberProductsApi(baseUrl),
                Species: new TimberSpeciesApi(baseUrl),
                Enterprises: new TimberEnterprisesApi(baseUrl),
                Volumes: new TimberVolumesApi(baseUrl),
                MapOLayers: new TimberMapOpenLayersApi(),
                AdvancedSearch: new TimberAdvancedSearchApi(baseUrl),
                Logs: new TimberLogsApi(baseUrl),
                Library: new TimberLibraryApi(baseUrl),
                Download: new TimberDownloadApi(baseUrl)
            }
        }
    }
})