import { BaseTimberApi } from '@/core/BaseTimberApi';
import { LoaderState } from '../core/LoaderState'


export class TimberLogsApi  extends BaseTimberApi {
    constructor(baseUrl: String) {
        super(baseUrl, "logs");
    }

    createFaleConosco(options:any, onreceive:any, loader: LoaderState) {
        loader(true);
        this.post("salesforce/fale-conosco", options, (x => {
            onreceive(x.data as []);
            loader(false);
        }));
    } 
    
    createInscrevase(options:any, onreceive:any, loader: LoaderState) {
        loader(true);
        this.post("salesforce/inscreva-se", options, (x => {
            onreceive(x.data as []);
            loader(false);
        }));
    }     
}