import { createApp } from 'vue'
import App from './components/templates/Layout.vue'
import { createI18n } from 'vue-i18n'
import routes from './routes'
import TimberApi from './mixins/TimberApi'
import { formatNumber, formatDate, formatDateWithMonth } from "./core/numeric.utils"
import VueSocialSharing from 'vue-social-sharing'
import VuePapaParse from 'vue-papa-parse'

import 'vue3-openlayers/dist/vue3-openlayers.css'
import emitterPlugin from '@/core/Emitter';
import VueAwesomePaginate from "vue-awesome-paginate";
import utilMixin from './mixins/utilMixin'

// Icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUserSecret, faXmark, faFilter, faChartLine, faThumbTack, faUpRightFromSquare, faLayerGroup, faTags} from '@fortawesome/free-solid-svg-icons'
library.add(faUserSecret, faXmark,faFilter, faChartLine, faThumbTack, faUpRightFromSquare, faLayerGroup, faTags, faFacebook, faLinkedin, faWhatsapp, faTwitter)

// Styles
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "vue-awesome-paginate/dist/style.css";

// Loader
import Loader from './components/atoms/Loader.vue';
import { faFacebook, faLinkedin, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

const i18n = createI18n({});
const app = createApp(App)
    .use(i18n)
    .use(routes)
    .use(routes)
    .use(VueSocialSharing)
    .use(emitterPlugin)
    .use(VuePapaParse)
    .use(VueAwesomePaginate)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('Loader', Loader)
    .mixin(utilMixin)
    .mixin(TimberApi);
app.config.globalProperties.$filters = {
    formatNumber(number:number,fixed:number) {
        return formatNumber(number,fixed)
    },
    formatDate(date:string) {
        const _date = new Date(date);
        return formatDate(_date)
    },
    formatDateWithMonth(date:string) {
        const _date = new Date(date);
        return formatDateWithMonth(_date)
    }        
}
app.mount('#app');


