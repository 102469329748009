import { BaseTimberApi, IBasicFilterParams, TimberSearchType } from '@/core/BaseTimberApi';
import { LoaderState } from '../core/LoaderState'

interface TimberSpeciesResultItem {
    nome_popular: String,
    id: Number
}

interface TimberSpeciesTopResultItem {
    nome_popular: String,
    volume_total_agg: Number,
    volume_total_ranking: Number
}

interface TimberSpeciesGet {
    (data: Array<TimberSpeciesResultItem>): void;
}

interface TimberSpeciesGetTop {
    (data: Array<TimberSpeciesTopResultItem>): void;
}

export class TimberSpeciesApi extends BaseTimberApi {
    constructor(baseUrl: String) {
        super(baseUrl, "species");
    }

    get(type: TimberSearchType, onreceive: TimberSpeciesGet, loader: LoaderState) {
        loader(true);     

        this.postFilters("get/" + this.parseRouteEndpoint(type), this.BLANK_FILTER, (x => {
            onreceive(x.data as TimberSpeciesResultItem[]);
            loader(false);
        }));
    }

    top(type: TimberSearchType, filter: IBasicFilterParams, onreceive: TimberSpeciesGetTop, loader: LoaderState) {
        loader(true);     

        this.postFilters("top/" + this.parseRouteEndpoint(type), filter, (x => {
            onreceive(x.data as Array<TimberSpeciesTopResultItem>);
            loader(false);
        }));
    }

    search(term: String, onreceive: TimberSpeciesGet, loader: LoaderState) {
        loader(true); 

        const filter = {
            term: term
        };

        this.post("search", filter, (x => {
            onreceive(x.data as Array<TimberSpeciesResultItem>);
            loader(false);
        }));
    }

    getAll(onreceive: TimberSpeciesGet, loader: LoaderState) {
        loader(true); 

        this.post("get/all", {}, (x => {
            onreceive(x.data as Array<TimberSpeciesResultItem>);
            loader(false);
        }));        
    }    
}