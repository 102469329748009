export default {
    methods: {
        mxUtilScrollMeTo(ref) {
            let refName = ref
            let bodyRect = document.body.getBoundingClientRect()
            //remove # from hash
            refName = refName.replace("#", "");
            let element = document.querySelector('[anchor=' + refName + ']')
            if (element != null) {
                let elemAnchor = element.getBoundingClientRect();
                let top = (elemAnchor.top - bodyRect.top);
                window.scrollTo(0, top)
            }
        }
    }
}