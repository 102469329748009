import { BaseTimberApi } from '@/core/BaseTimberApi';
import { LoaderState } from '../core/LoaderState'

interface TimberLibraryItem {
    id: String,
    categorias_id: String,
    titulo: String,
    slug: String,
    arquivo: String,
    palavras_chave: String,
    status: String,
    adwords: String,
    created_at: String,
    updated_at: String,
    lead: String,
    categoria: String,
    link: String,
    download_img: String,
    download_texto: String,
    id_referencia: String
}


interface TimberLibraryGet {
    (data: Array<TimberLibraryItem>): void;
}

function translateCategories(rows: any) {
        const l = rows.length
        for( let i=0; i<l; i++) {
            if (rows[i].categoria == "Publicação") {
                rows[i].categoria = 'Boletins'
            }
            if (rows[i].categoria == "Relatório") {
                rows[i].categoria = 'Estudos'
            }            
            
        }
        return rows   
}

export class TimberLibraryApi  extends BaseTimberApi {
    constructor(baseUrl: String) {
        super(baseUrl, "library");
    }

    count(filter: Object, onreceive: TimberLibraryGet, loader: LoaderState) {
        loader(true);
        this.post("list", filter, (x => {
            onreceive(x.data as TimberLibraryItem[]);
            loader(false);
        }));
    }   

    get( filter: Object, page: Number, pageSize: Number, onreceive: TimberLibraryGet, loader: LoaderState) {
        const newFilter: any = filter;
        newFilter.page = page;
        newFilter.pageSize = pageSize;

        loader(true);
        this.post("list", newFilter, (x => {
            const translate = translateCategories(x.data)
            onreceive(translate as TimberLibraryItem[]);
            loader(false);
        }));
    }   
}