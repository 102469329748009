import { createWebHistory, createRouter } from "vue-router";
const Home = () =>
    import ( /* webpackChunkName: "home" */ './pages/Home.vue')
const Transport = () =>
    import ( /* webpackChunkName: "transport" */ './pages/Transport.vue')
const Contact = () =>
    import ( /* webpackChunkName: "Contact" */ './pages/Contact.vue')
    const Signup = () =>
    import ( /* webpackChunkName: "Signup" */ './pages/Signup.vue')    
const Library = () =>
    import ( /* webpackChunkName: "library" */ './pages/Library.vue')
const AdvancedSearch = () =>
    import ( /* webpackChunkName: "AdvancedSearch" */ './pages/AdvancedSearch.vue')
const About = () =>
    import ( /* webpackChunkName: "about" */ './pages/About.vue')    

const Page404 = () =>
    import ( /* webpackChunkName: "page404" */ './pages/404.vue')    

const routes = [{
        path: "/fluxo-madeireiro",
        name: "transporte",
        component: Transport,
    },
    {
        path: "/facaparte/fale-conosco",
        name: "contact",
        component: Contact,
    },
    {
        path: "/facaparte/inscreva-se",
        name: "signup",
        component: Signup,
    },    
    {
        path: "/biblioteca",
        name: "biblioteca",
        component: Library,
    },
    {
        path: "/sobre",
        name: "sobre",
        component: About,
    },
    {
        path: "/consultaavancada",
        name: "consultaavancada",
        component: AdvancedSearch,
    },
    {
        path: "/",
        name: "home",
        component: Home,
    },
    { path: '/404', component: Page404 },  
    { path: '/:pathMatch(.*)*', redirect: '/404' }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;