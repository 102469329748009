import { BaseTimberApi, IBasicFilterParams, TimberSearchType } from '@/core/BaseTimberApi';
import { LoaderState } from '../core/LoaderState'

interface TimberDownloadItem {
    id:String,
    data_emissao_simplificada:String,
    municipio:String,
    sigla_uf:String,
    uf_origem:String,
    municipio_origem:String,
    uf_destino:String,
    municipio_destino:String,    
    nome:String,
    nome_cientifico:String,
    unidade_medida:String,
    volume_total:String
}

interface TimberDownloadGet {
    (data: Array<TimberDownloadItem>): void;
}

export class TimberDownloadApi extends BaseTimberApi {
    constructor(baseUrl: String) {
        super(baseUrl, 'downloads');
    }
    
    get(type: TimberSearchType, filter: IBasicFilterParams, onreceive: TimberDownloadGet, loader: LoaderState) {
        loader(true)
        this.postFilters(this.parseDownloadRouteEndpoint(type,filter), filter, (x => {
            onreceive(x.data as Array<TimberDownloadItem>)
            loader(false)
        }));
    }
}