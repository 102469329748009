import { BaseTimberApi} from '@/core/BaseTimberApi';
import { LoaderState } from '../core/LoaderState'

interface TimberStatesResultItem {
    cod_uf: Number,
    sigla_uf: String
}

interface TimberStatesGetAll {
    (data: Array<TimberStatesResultItem>): void;
}

export class TimberStatesApi extends BaseTimberApi {
    constructor(baseUrl: String) {
        super(baseUrl, 'states');
    }
    getAll(onreceive: TimberStatesGetAll, loader: LoaderState) {
        loader(true);     

        this.post("get/all", {}, (x => {
            onreceive(x.data as Array<TimberStatesResultItem>);
            loader(false);
        }));
    }      
}