import { BaseTimberApi, IBasicFilterParams, TimberSearchType } from '@/core/BaseTimberApi';
import { LoaderState } from '../core/LoaderState'

interface TimberProductsResultItem {
    nome: String,
    id: Number,
}

interface TimberProductsTopItem {
    nome: String,
    volume_total_agg: Number,
    volume_total_ranking: Number
}

interface TimberProductsGet {
    (data: Array<TimberProductsResultItem>): void;
}

interface TimberProductsGetTop {
    (data: Array<TimberProductsTopItem>): void;
}

export class TimberProductsApi  extends BaseTimberApi {
    constructor(baseUrl: String) {
        super(baseUrl, "products");
    }

    top(type: TimberSearchType, filter: IBasicFilterParams, onreceive: TimberProductsGetTop, loader: LoaderState) {
        loader(true);     

        this.postFilters("top/" + this.parseRouteEndpoint(type), filter, (x => {
            onreceive(x.data as Array<TimberProductsTopItem>);
            loader(false);
        }));
    }

    get(type: TimberSearchType, onreceive: TimberProductsGet, loader: LoaderState) {
        loader(true);     

        this.postFilters("get/" + this.parseRouteEndpoint(type), this.BLANK_FILTER, (x => {
            onreceive(x.data as TimberProductsResultItem[]);
            loader(false);
        }));
    }   

    search(term: String, onreceive: TimberProductsGet, loader: LoaderState) {
        loader(true); 

        const filter = {
            term: term
        };

        this.post("search", filter, (x => {
            onreceive(x.data as Array<TimberProductsResultItem>);
            loader(false);
        }));
    }

    getAll(onreceive: TimberProductsGet, loader: LoaderState) {
        loader(true); 

        this.post("get/all", {}, (x => {
            onreceive(x.data as Array<TimberProductsResultItem>);
            loader(false);
        }));        
    }
}