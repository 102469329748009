import axios, { AxiosInstance, AxiosResponse } from "axios";

export interface IDateItemsFilterParams {
    day: String,
    month: String,
    year: String,
}
export interface IBasicFilterParams {
    species: Array<Number>,
    products: Array<Number>,
    data_emissao_simplificada: Array<String>,
    forceUnidadeMedida: Boolean,
    cd_mun_orig: String,
    cd_mun_dest: String,
    orientationType: String
}

export interface IAdvancedTypeFilter {
    general:Boolean
}


export interface IAdvancedFilterParams {
    species: Array<Number>,
    products: Array<Number>,
    data_emissao: Array<String>,
    forceUnidadeMedida: Boolean,
    cd_mun_orig: String,
    cd_mun_dest: String,
    cd_uf_orig: String,
    cd_uf_dest: String,    
    cpf_cnpj_orig: String,
    name_enterprise: String
}

export interface IAdvancedDocFilterParams {
    register: String,
    forceUnidadeMedida: Boolean
}

export interface TimberApiResponse {
    (data: AxiosResponse<any,any>): void;
}

export enum TimberSearchType {
    Source = "source",
    Destination = "destination"
}

export interface TimberStatisticsItem {
    count: Number
}

export interface TimberStatisticsGetTotal {
    (data: Array<TimberStatisticsItem>): void;
}

export class BaseTimberApi {
    baseUrl: String;
    endpoint: String;
    client: AxiosInstance;

    BLANK_FILTER: IBasicFilterParams;

    BLANK_FILTER_ADVANCED: IAdvancedFilterParams;
    
    BLANK_DOC_FILTER_ADVANCED: IAdvancedDocFilterParams;

    axiosCancelSource: any

    constructor(baseUrl: String, endpoint: String) {
        this.baseUrl = baseUrl;
        this.endpoint = endpoint;
        this.BLANK_FILTER =  {
            species: [],
            products: [],
            data_emissao_simplificada: [],
            forceUnidadeMedida: false,
            cd_mun_orig: "",
            cd_mun_dest: "",
            orientationType: ''
        };

        this.BLANK_FILTER_ADVANCED =  {
            species: [],
            products: [],
            data_emissao: [],
            forceUnidadeMedida: true,
            cd_mun_orig: '',
            cd_mun_dest: '',
            cd_uf_orig: '',
            cd_uf_dest: '',  
            cpf_cnpj_orig: '',
            name_enterprise: ''         
        };  
        
        this.BLANK_DOC_FILTER_ADVANCED = {
            register: '',
            forceUnidadeMedida: true,
        }

        axios.defaults.headers.post['Content-Type'] = 'application/json';

        this.client = axios.create({
            baseURL: this.baseUrl + "/" + this.endpoint
        });
    }

    parseRouteEndpoint(type: TimberSearchType) {
        const routeEndpoint = type == TimberSearchType.Destination ? "destination" : (type == TimberSearchType.Source ? "source" : "unk");
        return routeEndpoint;
    }

    parseDownloadRouteEndpoint(type: TimberSearchType, filter: IBasicFilterParams) {
        let routeEndpoint = ""
        if (type == TimberSearchType.Destination && filter.cd_mun_orig != "" && filter.cd_mun_dest == "") {
            routeEndpoint = "destination_by_source"
        }
        if (type == TimberSearchType.Source && filter.cd_mun_orig == "" && filter.cd_mun_dest != "") {
            routeEndpoint = "source_by_destination"
        } 
        if (type == TimberSearchType.Destination && filter.cd_mun_orig == "" && filter.cd_mun_dest == "") {
            routeEndpoint = "destination"
        }
        if (type == TimberSearchType.Source && filter.cd_mun_orig == "" && filter.cd_mun_dest == "") {
            routeEndpoint = "source"
        }           
        return routeEndpoint;
    }    

    post(route: String, args: any, onResponse: TimberApiResponse) {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        this.axiosCancelSource = source
        this.client.post('/' + route, args, {
            cancelToken: source.token
          })
        .then((x) => {
            onResponse(x);
        })
    }

    postFilters(route: String, args: IBasicFilterParams, onResponse: TimberApiResponse) {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        this.axiosCancelSource = source
        this.client.post('/' + route, args, {
            cancelToken: source.token
          })
        .then((x) => {
            onResponse(x);
        })
    }

    postAdvancedFilters(route: String, args: IAdvancedFilterParams, onResponse: TimberApiResponse) {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        this.axiosCancelSource = source
        this.client.post('/' + route, args, {
            cancelToken: source.token
          })
        .then((x) => {
            onResponse(x);
        })
    }    
}