import { BaseTimberApi, IBasicFilterParams, TimberSearchType } from '@/core/BaseTimberApi';
import { LoaderState } from '../core/LoaderState'

interface TimberCitiesTopResultItem {
    sigla_uf: String,
    nm_mun: String,
    volume_total_agg: Number,
    volume_total_ranking: Number,
}

interface TimberCitiesGetTop {
    (data: Array<TimberCitiesTopResultItem>): void;
}

export class TimberCitiesApi extends BaseTimberApi {
    constructor(baseUrl: String) {
        super(baseUrl, 'cities');
    }

    top(type: TimberSearchType, filter: IBasicFilterParams, onreceive: TimberCitiesGetTop, loader: LoaderState) {
        loader(true);     

        this.postFilters("top/" + this.parseRouteEndpoint(type), filter, (x => {
            onreceive(x.data as Array<TimberCitiesTopResultItem>);
            loader(false);
        }));
    }

    getAll(onreceive: TimberCitiesGetTop, loader: LoaderState) {
        loader(true);     

        this.post("get/all", {}, (x => {
            onreceive(x.data as Array<TimberCitiesTopResultItem>);
            loader(false);
        }));
    }      
}